import ChevronDownIcon from "virtual:icons/lucide/chevron-down";
import CircleDollarSignIcon from "virtual:icons/lucide/circle-dollar-sign";
import CircleUserIcon from "virtual:icons/lucide/circle-user";
import CoinsIcon from "virtual:icons/lucide/coins";
import CreditCardIcon from "virtual:icons/lucide/credit-card";
import CrownIcon from "virtual:icons/lucide/crown";
import GalleryVerticalEndIcon from "virtual:icons/lucide/gallery-vertical-end";
import HistoryIcon from "virtual:icons/lucide/history";
import HomeIcon from "virtual:icons/lucide/home";
import LogInIcon from "virtual:icons/lucide/log-in";
import LogOutIcon from "virtual:icons/lucide/log-out";
import MenuIcon from "virtual:icons/lucide/menu";
import MessageSquareWarningIcon from "virtual:icons/lucide/message-square-warning";
import MoonIcon from "virtual:icons/lucide/moon";
import PieChartIcon from "virtual:icons/lucide/pie-chart";
import SettingsIcon from "virtual:icons/lucide/settings";
import StarsIcon from "virtual:icons/lucide/stars";
import SunIcon from "virtual:icons/lucide/sun";
import TagIcon from "virtual:icons/lucide/tag";
import TrophyIcon from "virtual:icons/lucide/trophy";
import UserPlusIcon from "virtual:icons/lucide/user-plus";
import XIcon from "virtual:icons/lucide/x";
import { SignOutButton, SignedIn, SignedOut, useUser as useClerkUser } from "@clerk/remix";
import { getFormProps, useForm } from "@conform-to/react";
import { Link, NavLink, useFetcher, useLocation, useMatches, useNavigate, useParams } from "@remix-run/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { $path } from "remix-routes";

import { useChatSettings } from "#app/hooks/use-chat-settings";
import { useIsPending } from "#app/hooks/use-is-pending";
import { useRequestInfo } from "#app/hooks/use-request-info";
import { useUser } from "#app/hooks/use-user";
import { type action, useOptimisticThemeMode } from "#app/root";
import type { action as nsfwAction } from "#app/routes/resources+/nsfw";
import { cx } from "#cva.config";

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogClose,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogTitle,
} from "../alert-dialog";
import { Button } from "../button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../dropdown";
import { LanguageSwitcher } from "../language-switcher";
import { Separator } from "../separator";
import { Switch } from "../switch";

export function Header({
	isOpen,
	setOpen,
}: {
	isOpen: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const user = useUser();
	const { user: clerkUser } = useClerkUser();
	const { t } = useTranslation("common");

	return (
		<div className="sticky top-0 z-40 flex h-16 shrink-0 items-center justify-end gap-2 bg-background px-4 lg:border-b dark:bg-[#202035] lg:px-8 sm:px-6">
			<Link to="/">
				<div className="relative aspect-[763/185] w-28 max-w-full">
					<img alt="timple.ai" className="size-full" src="/logo.svg" />
				</div>
			</Link>

			<div className="ml-4 hidden h-full lg:flex lg:gap-1">
				<NavLink
					className="inline-flex h-full items-center gap-2 border-b-4 border-b-transparent px-3 text-sm transition aria-current-page:border-b-primary dark:aria-current-page:border-b-white aria-current-page:text-primary dark:aria-current-page:text-white"
					end
					to={$path("/")}
				>
					<HomeIcon className="shrink-0" />
					<span>Home</span>
				</NavLink>
				<NavLink
					className="inline-flex h-full items-center gap-2 border-b-4 border-b-transparent px-3 text-sm transition aria-current-page:border-b-primary dark:aria-current-page:border-b-white aria-current-page:text-primary dark:aria-current-page:text-white"
					end
					to={$path("/following")}
				>
					<GalleryVerticalEndIcon className="shrink-0" />
					<span>{t("following")}</span>
				</NavLink>
				<NavLink
					className="inline-flex h-full items-center gap-2 border-b-4 border-b-transparent px-3 text-sm transition aria-current-page:border-b-primary dark:aria-current-page:border-b-white aria-current-page:text-primary dark:aria-current-page:text-white"
					end
					to={$path("/creators")}
				>
					<TrophyIcon className="shrink-0" />
					<span>{t("topCreators")}</span>
				</NavLink>
				<NavLink
					className="inline-flex h-full items-center gap-2 border-b-4 border-b-transparent px-3 text-sm transition aria-current-page:border-b-primary dark:aria-current-page:border-b-white aria-current-page:text-primary dark:aria-current-page:text-white"
					end
					to={$path("/market")}
				>
					<CircleDollarSignIcon className="shrink-0" />
					<span>{t("market")}</span>
				</NavLink>
				<NavLink
					className="inline-flex h-full items-center gap-2 border-b-4 border-b-transparent px-3 text-sm transition aria-current-page:border-b-primary dark:aria-current-page:border-b-white aria-current-page:text-primary dark:aria-current-page:text-white"
					end
					to={$path("/tokens")}
				>
					<CoinsIcon className="shrink-0" />
					<span>Tokens</span>
				</NavLink>
			</div>

			{/* menu sidebar action */}
			<button
				className="-m-2.5 inline-flex h-10 items-center justify-center rounded-lg bg-transparent p-2.5 font-medium text-gray-500 text-sm ring-offset-background transition-colors disabled:pointer-events-none lg:hidden dark:hover:bg-zinc-700/50 hover:bg-slate-100 dark:text-zinc-400 hover:text-accent-foreground disabled:opacity-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
				onClick={() => setOpen(!isOpen)}
				type="button"
			>
				<span className="sr-only">{t("openSidebar")}</span>
				<MenuIcon className="size-6" />
			</button>

			<div className="grow bg-transparent" />
			<div className="flex items-stretch gap-1 lg:gap-3">
				<NSFWMode className="flex" />
				<div className="hidden lg:block">
					<LanguageSwitcher />
				</div>

				<Separator
					className="m-1 h-auto min-h-full bg-transparent dark:lg:bg-zinc-700 lg:bg-gray-300/40"
					orientation="vertical"
				/>

				<Button asChild className="hidden xl:inline-flex">
					<Link to={$path("/characters/create")}>
						<UserPlusIcon />
						{t("createCharacter")}
					</Link>
				</Button>
				<Button $size="icon" $variant="ghost" asChild className="hidden sm:inline-flex xl:hidden">
					<Link to={$path("/characters/create")}>
						<UserPlusIcon className="pointer-events-none size-5 text-gray-500 xl:hidden dark:text-zinc-400" />
						<span className="sr-only">{t("createCharacter")}</span>
					</Link>
				</Button>

				<ThemeSwitch />

				<Separator
					className="m-1 h-auto min-h-full bg-transparent dark:bg-transparent dark:lg:bg-zinc-700 lg:bg-gray-300/40"
					orientation="vertical"
				/>

				<div className="flex min-w-10 items-center gap-2 rounded-full">
					<SignedIn>
						<DropdownMenu>
							<DropdownMenuTrigger
								className={cx(
									"relative flex size-10 items-center gap-2 rounded-full border border-zinc-200 ps-px outline-none transition-all lg:w-auto dark:border-zinc-700 dark:hover:bg-zinc-700/50 hover:bg-zinc-100/75",
									user?.permissions.badge &&
										"border-primary/90 bg-gradient-to-br from-primary to-secondary shadow-lg shadow-red-600/[.15] dark:border-primary/90 dark:hover:border-secondary hover:border-secondary hover:from-secondary hover:to-primary",
								)}
							>
								<span className="sr-only">{t("openUserMenu")}</span>
								<div className="relative size-9 shrink-0 overflow-hidden rounded-full bg-indigo-500/10 dark:bg-zinc-900">
									<img
										alt={user?.username || clerkUser?.username || ""}
										className="size-9 rounded-full object-cover"
										decoding="async"
										fetchPriority="high"
										loading="eager"
										src={user?.imageUrl || clerkUser?.imageUrl || ""}
									/>
								</div>

								{user?.permissions.badge ? (
									<div className="-bottom-0.5 -left-1 absolute rounded-full bg-gradient-to-b from-primary to-secondary p-1 text-white lg:hidden">
										<CrownIcon className="size-[11px] stroke-[1.35]" />
									</div>
								) : null}
								<span className="hidden items-center gap-2 lg:inline-flex">
									{user?.permissions.badge ? <CrownIcon className="text-yellow-300 md:mr-0 lg:block" /> : null}

									<span className="font-bold text-xs dark:text-white">@{user?.username || clerkUser?.username}</span>
								</span>

								<ChevronDownIcon className="mr-1 hidden size-5 text-white/75 lg:block dark:text-white/75" />
							</DropdownMenuTrigger>

							<DropdownMenuContent
								align="end"
								className={cx(
									"flex origin-top-right flex-col rounded-xl border border-gray-200/75 bg-background p-2 text-[inherit] shadow-xl dark:border-zinc-700/50 dark:bg-[#202035] focus:outline-none",
									user?.isAdmin ? "w-auto" : "w-40",
								)}
							>
								<DropdownMenuItem
									asChild
									className="inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none"
								>
									<Link
										to={$path("/creators/:username", {
											username: user?.username || clerkUser?.username || "",
										})}
									>
										<CircleUserIcon className="size-4" />
										<span>{t("profile")}</span>
									</Link>
								</DropdownMenuItem>

								<DropdownMenuItem
									asChild
									className="inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none"
								>
									<Link to="/account">
										<SettingsIcon className="size-4" />
										<span>{t("account")}</span>
									</Link>
								</DropdownMenuItem>

								<DropdownMenuItem
									asChild
									className="inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none"
								>
									<Link to={$path("/my-reports")}>
										<MessageSquareWarningIcon className="size-4" />
										<span>My reports</span>
									</Link>
								</DropdownMenuItem>

								{user?.isAdmin ? (
									<>
										<DropdownMenuSeparator />

										<DropdownMenuLabel>Admin</DropdownMenuLabel>

										<DropdownMenuItem
											asChild
											className="inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none"
										>
											<Link to={$path("/review-characters")}>
												<PieChartIcon className="size-4" />
												<span>{t("reviewCharacters")}</span>
											</Link>
										</DropdownMenuItem>

										<DropdownMenuItem
											asChild
											className="inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none"
										>
											<Link to={$path("/stats")}>
												<PieChartIcon className="size-4" />
												<span>{t("stats")}</span>
											</Link>
										</DropdownMenuItem>

										<DropdownMenuItem
											asChild
											className="inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none"
										>
											<Link to={$path("/features")}>
												<StarsIcon className="size-4" />
												<span>{t("features")}</span>
											</Link>
										</DropdownMenuItem>

										<DropdownMenuItem
											asChild
											className="inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none"
										>
											<Link to={$path("/tags/create")}>
												<TagIcon className="size-4" />
												<span>{t("createTag")}</span>
											</Link>
										</DropdownMenuItem>

										<DropdownMenuItem
											asChild
											className="inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none"
										>
											<Link to={$path("/plans/create")}>
												<CreditCardIcon className="size-4" />
												<span>{t("createNewPlan")}</span>
											</Link>
										</DropdownMenuItem>

										<DropdownMenuItem
											asChild
											className="inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none"
										>
											<Link to={$path("/chat/history")}>
												<HistoryIcon className="size-4" />
												<span>{t("chatHistory")}</span>
											</Link>
										</DropdownMenuItem>

										<DropdownMenuItem
											asChild
											className="inline-flex h-10 w-full items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none"
										>
											<Link to={$path("/admin/reports")}>
												<MessageSquareWarningIcon className="size-4" />
												<span>{t("reports")}</span>
											</Link>
										</DropdownMenuItem>

										<DropdownMenuSeparator />
									</>
								) : null}

								<SignOutButton>
									<DropdownMenuItem className="inline-flex h-10 w-full cursor-default items-center justify-start gap-2 rounded-lg bg-transparent px-4 py-2 font-medium text-sm ring-offset-background transition-colors dark:hover:bg-zinc-700/50 hover:bg-slate-100 rdx-highlighted:bg-slate-100 hover:text-accent-foreground rdx-highlighted:text-accent-foreground focus-visible:outline-none">
										<LogOutIcon className="size-4" />
										<span>{t("signOut")}</span>
									</DropdownMenuItem>
								</SignOutButton>
							</DropdownMenuContent>
						</DropdownMenu>
					</SignedIn>

					<SignedOut>
						<Button asChild>
							<Link
								to={$path("/login/*", {
									"*": "",
								})}
							>
								<LogInIcon />
								<span className="max-[400px]:hidden">{t("signIn")}</span>
							</Link>
						</Button>
					</SignedOut>
				</div>
			</div>
		</div>
	);
}

function NSFWMode(props: React.ComponentPropsWithoutRef<"div">) {
	const { userPrefs } = useRequestInfo();
	const fetcher = useFetcher<typeof nsfwAction>();
	const [form] = useForm({
		id: "nsfw-mode-form",
		// @ts-ignore
		lastResult: fetcher.data,
	});
	const [isOpen, setOpen] = useState(false);
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const mode = userPrefs.nsfw ?? "hide";
	const nextMode = mode === "hide" ? "show" : "hide";
	const hide18 = pathname.split("/")[1] === "chat";

	useEffect(() => {
		if (!userPrefs.nsfw) {
			setOpen(true);
		}
	}, [userPrefs.nsfw]);

	function onCheckedChange(checked: boolean) {
		if (checked) return setOpen(true);

		return fetcher.submit(
			{
				value: nextMode,
			},
			{
				action: "/resources/nsfw",
				method: "POST",
			},
		);
	}

	return (
		<div className={cx("flex items-center gap-2", props.className)}>
			{!hide18 && (
				<>
					<label className="font-bold text-primary dark:text-white" htmlFor="nsfw-mode">
						+18
					</label>

					<Switch checked={mode === "show"} id="nsfw-mode" name="nsfw-mode" onCheckedChange={onCheckedChange} />
				</>
			)}

			{!pathname.endsWith("underage") ? (
				<AlertDialog onOpenChange={setOpen} open={isOpen}>
					<AlertDialogContent>
						<AlertDialogTitle>Confirm your age</AlertDialogTitle>
						<AlertDialogDescription>
							You may encounter sensitive or adult content that’s not for everyone. To view it, confirm your age.
						</AlertDialogDescription>

						<AlertDialogFooter>
							<AlertDialogCancel onClick={() => navigate("/underage")}>Go back</AlertDialogCancel>
							<fetcher.Form action="/resources/nsfw" method="POST" {...getFormProps(form)}>
								<input name="value" type="hidden" value={nextMode} />

								<AlertDialogAction type="submit">I'm over 18</AlertDialogAction>
							</fetcher.Form>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialog>
			) : null}
		</div>
	);
}

export function ThemeSwitch() {
	const { userPrefs } = useRequestInfo();
	const fetcher = useFetcher<typeof action>();
	const [form] = useForm({
		id: "theme-switch",
		lastResult: fetcher.data?.result,
	});
	const optimisticMode = useOptimisticThemeMode();
	const mode = optimisticMode ?? userPrefs.theme ?? "dark";
	const nextMode = mode === "light" ? "dark" : "light";
	const modeIcon = {
		dark: <MoonIcon className="size-[1.2rem] transition-all" />,
		light: <SunIcon className="size-[1.2rem] transition-all" />,
	};
	const isPending = useIsPending();
	const { chatId } = useParams<{
		chatId: string;
	}>();
	const [settings] = useChatSettings(chatId);

	return (
		<fetcher.Form
			action="/"
			className={cx(
				"size-10",
				settings.isImmersiveModeOn ? "glass-btn rounded-full" : "rounded-lg border dark:border-zinc-700",
			)}
			method="POST"
			{...getFormProps(form)}
		>
			<input name="theme" type="hidden" value={nextMode} />
			<Button
				$size="icon"
				$variant={settings.isImmersiveModeOn ? "glass-btn-transparent" : "ghost"}
				className="flex size-full grow-0 items-center justify-center rounded-[calc(0.5rem-1px)] text-gray-500 dark:bg-[#202035] dark:hover:bg-zinc-700/50 dark:text-zinc-400 lg:dark:text-gray-300"
				disabled={isPending}
				type="submit"
			>
				{modeIcon[mode]}
				<span className="sr-only">Toggle theme, currently {mode}</span>
			</Button>
		</fetcher.Form>
	);
}
